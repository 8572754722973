import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js";
import distinctColors from "distinct-colors";

export default class extends Controller {
  static values = {
    url: String
  };

  async connect() {
    let canvas = this.element.innerHTML;

    const chartData = await this.fetchChartData();
    this.element.innerHTML = canvas;

    this.drawChart(chartData);
  }

  drawChart(data) {
    const palette = distinctColors({ count: 100 }),
      d = data.rent_averages,
      ctx = $(this.element).find(".chart"),
      comparable = data.comparable,
      created = data.created,
      noOfAvailable = data.no_of_available;

    let colorIndex = 1,
      color = palette[colorIndex++],
      datasets = [
        {
          borderColor: color.css(),
          backgroundColor: color.alpha(0.5).css(),
          label: d.title,
          yAxisID: "leftY",
          data: d.data,
        },
      ];

    if (comparable) {
      for (let c of comparable) {
        color = palette[colorIndex++];
        datasets.push({
          borderColor: color.css(),
          backgroundColor: color.alpha(0.5).css(),
          label: c.title,
          yAxisID: "leftY",
          data: c.data,
        });
      }
    }

    if (created) {
      color = palette[colorIndex++];
      datasets.push({
        borderColor: color.css(),
        backgroundColor: color.alpha(0.8).css(),
        label: created.title,
        yAxisID: "rightY",
        data: created.data,
        type: "bar",
      });
    }

    if (noOfAvailable) {
      color = palette[colorIndex++];
      datasets.push({
        borderColor: color.css(),
        backgroundColor: color.alpha(0.8).css(),
        label: noOfAvailable.title,
        yAxisID: "rightY",
        data: noOfAvailable.data,
        type: "bar",
      });
    }

    let options = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "month",
              parser: "YYYY-MM-DD",
              round: true,
              source: "data",
              distribution: "series",
              displayFormats: {
                month: "MMM YYYY",
              },
              tooltipFormat: "MMM YYYY",
            },
          },
        ],
        yAxes: [
          {
            id: "leftY",
            type: "linear",
            position: "left",
            ticks: {
              beginAtZero: true,
            },
          },
          {
            id: "rightY",
            type: "linear",
            position: "right",
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    new Chart(ctx, {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: options,
    });
  }

  async fetchChartData() {
    let loaderHtml = "<div class='center'><div class='loader'></div></div>",
      errorHtml = "<div class='center'>Sorry, we're unable to fetch the data.</div>";

    this.element.innerHTML = loaderHtml;
    const res = await fetch(this.urlValue)
      .then(response => {
        if (!response.ok) {
          this.element.innerHTML = errorHtml;
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
      });

    return res;
  }
}
