import { Controller } from "@hotwired/stimulus";

import "@yaireo/tagify/src/tagify.scss";
import Tagify from "@yaireo/tagify";

import Axios from "axios";

export default class extends Controller {
  static targets = ["input"];
  static values = { limit: Number };

  connect() {
    this.whiteList = this.inputTarget.value.split(",").map(e => {
      console.log(e);
      return e.toString().trim();
    });
    console.log(this.whiteList);
    let limit = this.limitValue || 10;

    this.tagify = new Tagify(this.inputTarget, {
      whitelist: this.whiteList,
      maxTags: limit,
      validate: v => {
        console.log(v.value);
        return (
          v.value.toString().match(/^([0-9]{4}$|[\p{L}\s]{3,})/giu) !== null
        );
      },
      // pattern: /^([0-9]{4}$|[\p{L}\s]{3,})/giu,
      originalInputValueFormat: valuesArr => {
        return valuesArr.map(item => item.value).join(",");
      },
    });
    this.tagify.on("input", this.onTagifyInput);
  }
  async onTagifyInput(e) {
    // clear current whitelist
    e.detail.tagify.settings.whitelist.length = 0; // reset current whitelist
    // show loader & hide suggestions dropdown (if opened)
    if (e.detail.value.length < 2) return;

    e.detail.tagify.loading(true).dropdown.hide.call(e.detail.tagify);

    var newWhitelist = await Axios.get("/zip_codes/suggest", {
      params: { prefix: e.detail.value },
    }).then(response => {
      return response.data;
    });

    // replace tagify "whitelist" array values with new values
    // and add back the ones already choses as Tags
    e.detail.tagify.settings.whitelist.push(
      ...newWhitelist,
      ...e.detail.tagify.value,
    );

    // render the suggestions dropdown
    e.detail.tagify
      .loading(false)
      .dropdown.show.call(e.detail.tagify, e.detail.value);
  }

  async getWhitelistFromServer(value) {}
}
