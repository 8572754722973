import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];
  static values = {
    url: String,
  };

  connect() {
    let that = this,
      loaderHtml = "<div class='center'><div class='loader'></div></div>",
      errorHtml = "<div class='center'>Sorry, we're unable to fetch the data.</div>";

    this.containerTarget.innerHTML = loaderHtml;

    fetch(this.urlValue)
      .then(response => {
        if (!response.ok) {
          that.containerTarget.innerHTML = errorHtml;
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(response => {
        that.containerTarget.innerHTML = response;
      });
  }
}
