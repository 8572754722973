import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["btn"];
  connect() {
    let $btn = $(this.btnTarget);
    $btn.click();
    $btn.attr("disabled", "disabled");

    $btn.text("Redirecting to payment...");
  }
}
