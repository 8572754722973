import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let tmp = $(this.element).find(".js-template");
    this.parent = tmp.parent();
    this.template = tmp.clone();
    tmp.remove();

    const c = JSON.parse(this.data.get("comparable"));
    this.comparable = [];
    if (c && c.length) this.comparable = c;

    const incomming = JSON.parse(this.data.get("data"));
    if (incomming) {
      for (let d of incomming) {
        this.addNew(d);
      }
    }
  }

  addNew(data) {
    let clone = this.template.clone();
    clone.removeClass("d-none");
    if (data) {
      if (data.zipcode) {
        clone.find('[data-identifier="zipcode"]').val(data.zipcode);
      }
      if (data.size_from) {
        clone.find('[data-identifier="sizeFrom"]').val(data.size_from);
      }
      if (data.size_to) {
        clone.find('[data-identifier="sizeTo"]').val(data.size_to);
      }
      if (data.room_from) {
        clone.find('[data-identifier="roomFrom"]').val(data.room_from);
      }
      if (data.room_to) {
        clone.find('[data-identifier="roomTo"]').val(data.room_to);
      }
      if (data.types) {
        clone.find('[data-identifier|="types"]').removeAttr("checked");
        for (let t of data.types) {
          clone.find(`[data-identifier="types-${t}"]`).attr("checked", true);
        }
      }
      if (data.periods) {
        clone.find('[data-identifier|="periods"]').removeAttr("checked");
        for (let t of data.periods) {
          clone.find(`[data-identifier="periods-${t}"]`).attr("checked", true);
        }
      }
      if (data.rooms) {
        clone.find('[data-identifier|="rooms"]').removeAttr("checked");
        for (let t of data.rooms) {
          clone.find(`[data-identifier="rooms-${t}"]`).attr("checked", true);
        }
      }
      if (this.comparable) {
        let c = this.comparable.find(e => e.title == data.zipcode);
        if (c) {
          clone
            .find(".js-results")
            .children()
            .removeClass("d-none");
          clone.find(".js-avg").text(c.avg);
          clone.find(".js-count").text(c.count);
        }
      }
    }
    this.parent.append(clone);
  }

  remove(e) {
    console.log("remove...");
    console.log(e);
    $(e.target)
      .parents(".js-template")
      .remove();
  }
}
