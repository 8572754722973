// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("local-time").start();

require("trix");
require("@rails/actiontext");

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Rails = Rails;

window.Stimulus = Application.start();
const context = require.context("../controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

import axiosCSRF from "../utils/axios_csrf";

import "bootstrap";
import "data-confirm-modal";

import "../stylesheets/application.scss";
import * as $ from "jquery";
import "webpack-jquery-ui/slider";
import "webpack-jquery-ui/autocomplete";
import "webpack-jquery-ui/datepicker";
import "webpack-jquery-ui/css";

window.jQuery = $;
require("../utils/jquery.ui.touch-punch");

import "noty/lib/noty.css";
import "noty/src/themes/metroui.scss";
import Noty from "noty";
window.Noty = Noty;

var buildFlashMessage, buildFlashMessageFromResp, buildFlashMessages;

// ---
// generated by coffee-script 1.9.2

$(document).on("turbolinks:load", () => {
  axiosCSRF();

  $(".flash-messages .flash-message").each(function(index, el) {
    var $el, opts;
    $el = $(el);
    opts = {
      text: $el.find(".message").html(),
      type: $el.data("type"),
      title: $el.find(".title").text(),
    };
    return window.buildFlashMessage(opts);
  });

  $(".dropdown-menu .nav-link").each(function(index, el) {
    var $el = $(el);
    if ($el.attr("href") == window.location.pathname) {
      $el.addClass("active");
    }
  });

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $("figure.attachment .attachment__name").each(function(e) {
    var $fig = $(this).parents("figure");
    if ($fig.parent("action-text-attachment").length) return;
    if ($fig.hasClass("attachment--preview")) {
      $fig.find("figcaption").remove();
      $fig.find("img").addClass("img-fluid");
    } else {
      var a = $("<a></a>")
        .attr("href", $fig.data("trixAttachment").url)
        .attr("target", "_blank")
        .text($(this).text());
      $(this).replaceWith(a);
    }
  });
  $(".blur-content").each(function(b) {
    var $this = $(this);
    var el = $('<div class="blur-warning"></div>');
    el.html($this.data("premiumText")).appendTo(el);
    el.insertBefore($this);
  });

  var dateFormat = "dd-mm-yy",
    from = $("#date-from")
      .datepicker({
        dateFormat: dateFormat,
        defaultDate: "-2y",
        changeMonth: true,
        numberOfMonths: 1,
      })
      .on("change", function() {
        to.datepicker("option", "minDate", getDate(this));
      }),
    to = $("#date-to")
      .datepicker({
        dateFormat: dateFormat,
        defaultDate: "+1w",
        changeMonth: true,
        numberOfMonths: 1,
      })
      .on("change", function() {
        from.datepicker("option", "maxDate", getDate(this));
      });

  function getDate(element) {
    var date;
    try {
      date = $.datepicker.parseDate(dateFormat, element.value);
    } catch (error) {
      date = null;
    }
    return date;
  }
});

buildFlashMessage = function(opts) {
  var timeout, type;
  type = (function() {
    switch (opts.type) {
      case "success":
      case "info":
      case "notice":
        return "success";
      case "danger":
      case "warning":
      case "alert":
        return "warning";
      default:
        return type;
    }
  })();
  timeout = false;
  if (type === "success") {
    timeout = 3000;
  }
  return new window.Noty({
    timeout: timeout,
    type: type,
    text: opts.text,
    theme: "metroui",
    layout: "bottomRight",
  }).show();
};

buildFlashMessages = function(messages) {
  var msg, results, type;
  results = [];
  for (type in messages) {
    msg = messages[type];
    results.push(
      buildFlashMessage({
        type: type,
        text: msg,
      }),
    );
  }
  return results;
};

buildFlashMessageFromResp = function(resp) {
  var msg, ref, results, type;
  if (resp && resp.data && resp.data.meta && resp.data.meta.flash) {
    ref = resp.data.meta.flash;
    results = [];
    for (type in ref) {
      msg = ref[type];
      results.push(
        buildFlashMessage({
          type: type,
          text: msg,
        }),
      );
    }
    return results;
  }
};

window.buildFlashMessages = buildFlashMessages;
window.buildFlashMessage = buildFlashMessage;
window.buildFlashMessageFromResp = buildFlashMessageFromResp;
