import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["surveyForm"];

  connect() {
    this.switchSurveyFormVisibility();
  }
  changeAccountType(e) {
    this.switchSurveyFormVisibility();
  }

  switchSurveyFormVisibility() {
    if (this.accountType() == "private_person") {
      this.surveyFormTarget.style.display = "block";
    } else {
      this.surveyFormTarget.style.display = "none";
    }
  }

  accountType() {
    return "business";
    // return this.element.querySelector(
    //   'input[name="user[account_type]"]:checked',
    // ).value;
  }
}
