import axios from "axios";

export default function () {
  axios.defaults.headers.common = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };
  const tokenDom = document.querySelector("meta[name=csrf-token]");
  if (tokenDom) {
    const csrfToken = tokenDom.content;
    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  }
}
