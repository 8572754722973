import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["label", "input"];

  connect() {
    this.setLabel();
  }
  click(e) {
    e.preventDefault();
    let setChecked = !this.allChecked();
    for (let cb of this.inputTargets) {
      if (setChecked) cb.checked = true;
      else cb.checked = false;
    }
    this.setLabel();
    return false;
  }

  checked() {
    this.setLabel();
    return true;
  }

  allChecked() {
    for (let cb of this.inputTargets) {
      if (!cb.checked) return false;
    }
    return true;
  }

  setLabel() {
    if (this.allChecked()) {
      this.labelTarget.textContent = this.data.get("none");
    } else {
      this.labelTarget.textContent = this.data.get("all");
    }
  }
}
