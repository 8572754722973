import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js";
import distinctColors from "distinct-colors";

export default class extends Controller {
  static values = {
    url: String,
    noDataText: String
  };

  async connect() {
    let canvas = this.element.innerHTML;

    const chartData = await this.fetchChartData();
    this.element.innerHTML = canvas;

    this.drawChart(chartData);
  }

  drawChart(data) {
    if (data.length > 0 && !!data[0].dataset && data[0].dataset.length > 0) {
      const palette = distinctColors({ count: 100 });

      let colorIndex = 1,
        datasets = [],
        color;

      for (let d of data) {
        color = palette[colorIndex++];
        datasets.push({
          label: d.title,
          data: d.dataset,
          borderColor: color.css(),
          backgroundColor: color.alpha(0.5).css(),
        });
      }

      const ctx = $(this.element).find(".chart");

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: data[0].labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            xAxes: [
              {
                display: true,
                labelString: "Rent range",
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
                display: true,
                labelString: "Days to rent",
              },
            ],
          },
        },
      });
    } else {
      $(this.element.innerHTML = `<p>${this.noDataTextValue}</p>`);
    }
  }

  async fetchChartData() {
    let loaderHtml = "<div class='center'><div class='loader'></div></div>",
      errorHtml = "<div class='center'>Sorry, we're unable to fetch the data.</div>";

    this.element.innerHTML = loaderHtml;

    const res = await fetch(this.urlValue)
      .then(response => {
        if (!response.ok) {
          this.element.innerHTML = errorHtml;
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      });

    return res;
  }
}
