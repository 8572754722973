import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["slider", "from", "to"];

  connect() {
    this.initSlider();
  }

  initSlider() {
    var $min = $(this.fromTarget);
    var $max = $(this.toTarget);
    const min = parseInt($min.attr("min"));
    const max = parseInt($max.attr("max"));
    let slider = $(this.sliderTarget);
    slider.slider({
      range: true,
      min: min,
      max: max,
      values: [$min.val(), $max.val()],
      slide: function(event, ui) {
        $min.val(ui.values[0]);
        $max.val(ui.values[1]);
      },
    });
    $min.val(slider.slider("values", 0));
    $max.val(slider.slider("values", 1));

    var updateSlider = function() {
      slider.slider("option", "values", [$min.val(), $max.val()]);
    };
    $min.on("change", updateSlider);
    $max.on("change", updateSlider);
  }
}
