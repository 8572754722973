import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["input", "radio"];

  connect() {
    this.copyInputToRadio();
  }
  inputChanged(e) {
    this.copyInputToRadio();
  }

  copyInputToRadio() {
    this.radioTarget.value = this.inputTarget.value;
  }
}
